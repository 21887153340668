import React, { useState, useContext } from "react"
import { graphql } from 'gatsby'
import Img from "gatsby-image"

import { UserContext } from "../helpers/context"

import "./wer-wir-sind.css"

const checkMail = email => {
  const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
  return reg.test(email)
}

const getPhoneNumber = number => {
  // replace first 0 with +43
  // removes all blanks, / and (0)
  if (!number) return null
  return number.replace(/^0/g, "+43").replace(/\(.*\)|\/| /g, "")
}

export default ({ data }) => {
  const { younion } = data
  const { teams } = younion
  const user = useContext(UserContext)

  const [submitText, setSubmitText] = useState({ success: {}, error: {} })

  const validate = e => {
    const { email, phone, issue, form_tools_form_id } = e.target
    const error = {}

    if (!form_tools_form_id.value) {
      error.form = "Wählen Sie aus welche Art von Anfrage es sein soll."
    }

    if (!email.value && !phone.value) {
      error.requiredContact = "Email oder Telefon wurde nicht ausgefüllt."
    }

    if (!issue.value) {
      error.requiredIssue = "Das Anliegen wurde nicht ausgefüllt."
    }

    if (email.value && !checkMail(email.value)) {
      error.email = "Email ungültig."
    }

    setSubmitText({ success: {}, error })

    if (!Object.keys(error).length) {
      return true
    }

    return false
  }

  const onSubmit = e => {
    e.preventDefault()

    if (validate(e)) {
      const fd = new FormData(e.target)

      fetch(e.target.action, {
        method: "POST",
        redirect: "manual",
        body: fd,
      }).then(response => {
        if (response.type === "opaqueredirect") {
          // success
          setSubmitText({
            success: {
              text: "Ihre Anfrage wurde zugeschickt! Wir melden uns in Kürze.",
            },
            error: {},
          })
        } else {
          // failed
          setSubmitText({
            success: {},
            error: {
              failed:
                "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
            },
          })
        }
      })
    }
  }

  return (
    <div id="contact">
      <h1>Kontakte</h1>
      <div className="contact-team">
        {teams
          .sort((a, b) => a.order - b.order)
          .map(team => (
            <div key={team._id} className="contact-item">
              <div className="contact-image">
                <Img fluid={team.portrait.image.childImageSharp.fluid} />
              </div>
              <div className="contact-data">
                <table>
                  <tbody>
                    {team.function ? (
                      <tr>
                        <td>Funktion</td>
                        <td>{team.function}</td>
                      </tr>
                    ) : null}

                    {team.name || team.role ? (
                      <tr>
                        <td>Name</td>
                        <td>
                          {team.titleBefore ? (
                            <>
                              {team.titleBefore}
                              <br />
                            </>
                          ) : null}
                          <span className="contact-data-name">{team.name}</span>
                        </td>
                      </tr>
                    ) : null}

                    {team.adress ? (
                      <tr>
                        <td>Adresse</td>
                        <td>{team.adress}</td>
                      </tr>
                    ) : null}

                    {team.phone ? (
                      <tr>
                        <td>Telefon</td>
                        <td>
                          <a href={`tel:${getPhoneNumber(team.phone)}`}>
                            {team.phone}
                          </a>
                        </td>
                      </tr>
                    ) : null}

                    {team.mobile ? (
                      <tr>
                        <td>Mobiltelefon</td>
                        <td>
                          <a href={`tel:${getPhoneNumber(team.mobile)}`}>
                            {team.mobile}
                          </a>
                        </td>
                      </tr>
                    ) : null}

                    {team.email ? (
                      <tr>
                        <td>Mail</td>
                        <td>
                          <a href={`mailto:${team.email}`}>{team.email}</a>
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
      </div>

      <div className="contact-forms">
        <div className="contact-form">
          <form
            action="https://form.younion.qnipp.com/process.php"
            method="post"
            onSubmit={onSubmit}
          >
            <input
              type="hidden"
              name="form_tools_redirect_url"
              value="http://localhost:8000/wer-wir-sind"
            />
            <h3>
              {user?.sessionId
                ? "Dein Anliegen oder Rechtsbereichfragen:"
                : "Dein Anliegen:"}
            </h3>
            <div className="contact-form-inputs">
              <label>
                Name:
                <input type="text" id="name" name="name" />
              </label>
              <label>
                Email:
                <input type="text" id="email" name="email" />
              </label>
              <label>
                Telefon:
                <input type="text" id="phone" name="phone" />
              </label>

              {user?.sessionId ? (
                <div className="contact-form-switch">
                  <div>
                    <input
                      type="radio"
                      id="issue_form"
                      name="form_tools_form_id"
                      value="3"
                    />
                    <label htmlFor="issue_form">Anliegen</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="issue_right"
                      name="form_tools_form_id"
                      value="4"
                    />
                    <label htmlFor="issue_right">Rechtsbereich</label>
                  </div>
                </div>
              ) : (
                  <input type="hidden" name="form_tools_form_id" value="3" />
                )}
            </div>
            <textarea id="issue" name="issue" rows="12" />
            <div className="submit-text">
              {Object.keys(submitText.success).length
                ? submitText.success.text
                : null}
              {Object.keys(submitText.error).length
                ? Object.values(submitText.error).map(error => `${error} `)
                : null}
            </div>
            <button
              type="submit"
              disabled={Object.keys(submitText.success).length}
            >
              Absenden
            </button>
          </form>
        </div>
      </div>
      <div>
        <h4>Unsere Kontoverbindung:</h4>
        <div className="contact-text-before">ÖGB younion Burgenland</div>AT03
        1400 0381 1021 0024
        <br />
        <br />
        Mitgliedsbeiträge sind 1 % des Bruttogehalts für aktive
        <br /> <br />
        <div className="contact-text-before">Pensionisten</div> € 36,-- im Jahr
        <br />
        <div className="contact-text-before">Zweitmitgliedschaften</div> € 36,--
        im Jahr
        <br />
        <div className="contact-text-before">Arbeitssuchend</div> € 18,-- im
        Jahr
        <br />
        <br />
        Karenz erstes Jahr Beitragsfrei, das Mitglied muss die Gewerkschaft
        informieren!
        <br />
        Das zweite Jahr ist der Beitrag 18,-- im Jahr
      </div>
    </div>
  )
}

export const query = graphql`
  query {
    younion {
      teams {
        ...teamFields
      }
    }
  }
`
